import * as React from "react"
import styled from "styled-components"
import nameImg from "../../assets/custom-name.svg"
import aboutTextElement from "../../assets/about-text-element.svg"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"

const Text = styled.h2`
  font-size: 96px;
  line-height: 72px;
  font-family: "Yrsa";
  position: relative;
  pointer-events: all;

  @media (max-width: 1443px) {
    font-size: 56px;
    line-height: 42px;
  }

  @media (max-width: 1024px) {
    font-size: 96px;
    line-height: 72px;
  }

  @media (max-width: 850px) {
    font-size: 56px;
    line-height: 42px;
  }

  @media (max-width: 426px) {
    font-size: 43px;
    line-height: 34px;
  }

  &.name {
    &::before {
      content: "";
      position: absolute;
      background-image: url("${nameImg}");
      background-size: cover;
      width: 132px;
      height: 20px;
      bottom: -25px;
      right: 0;
    }
  }

  &.about {
    &::after {
      content: "";
      position: absolute;
      background-image: url("${aboutTextElement}");
      background-size: cover;
      width: 101px;
      height: 55px;
      bottom: 27px;
      right: 90px;
    }
  }

  &.work {
    &::after {
      content: "";
      position: absolute;
      background-image: url("${aboutTextElement}");
      background-size: cover;
      width: 73px;
      height: 55px;
      bottom: 65px;
      right: 0;
    }
  }

  &.contact {
    color: white;
    /* &::after {
      content: "";
      position: absolute;
      background-image: url("${aboutTextElement}");
      background-size: cover;
      width: 73px;
      height: 55px;
      bottom: 55px;
      right: 0;
    } */
  }
`

const HeadingH2 = ({ children, className }) => (
  <Text
    className={className}
    dangerouslySetInnerHTML={getHtmlFromRedactorField(children)}
  />
)

export default HeadingH2
